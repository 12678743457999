import { Routes, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@fontsource/pacifico'
import '@fontsource/comfortaa'
import { lazy, Suspense } from 'react'
const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const Press = lazy(() => import('./pages/Press'))
const Influencers = lazy(() => import('./pages/Influencers'))
const Team = lazy(() => import('./pages/Team'))

function App() {
	return (
		<div>
			<Routes>
				<Route
					path="/"
					element={
						<Suspense fallback={<>...</>}>
							<Home />
						</Suspense>
					}
				/>
				<Route
					path="about"
					element={
						<Suspense fallback={<>...</>}>
							<About />
						</Suspense>
					}
				/>
				<Route
					path="press"
					element={
						<Suspense fallback={<>...</>}>
							<Press />
						</Suspense>
					}
				/>
				<Route
					path="influencers"
					element={
						<Suspense fallback={<>...</>}>
							<Influencers />
						</Suspense>
					}
				/>
				<Route
					path="team"
					element={
						<Suspense fallback={<>...</>}>
							<Team />
						</Suspense>
					}
				/>
			</Routes>
		</div>
	)
}

export default App
